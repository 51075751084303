
// import React from "react";
import './style.css';

const CustomLoader = () => {
    return (
        <>
            <div className="loading">Loading&#8230;</div>
        </>
    )
}

export default CustomLoader;