import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import Web3 from "web3";
import { InputLabel, MenuItem, FormControl, NativeSelect, Box, Select, Typography, Grid, TextField } from "@material-ui/core"
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from "@material-ui/core/Tooltip";
import { convertUtf8ToHex } from "@walletconnect/utils";
import CustomLoader from "./loader";
// @ts-ignore
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnect from "@walletconnect/web3-provider";
// @ts-ignore
import Torus from "@toruslabs/torus-embed";
// @ts-ignore
import WalletLink from "walletlink";
import Pools from "./assets/Pools.json"
import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Modal from "./components/Modal";
import Header from "./components/Header";
import Loader from "./components/Loader";
import ModalResult from "./components/ModalResult";
import AccountAssets from "./components/AccountAssets";
import ConnectButton from "./components/ConnectButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiGetAccountAssets } from "./helpers/api";
import {
  hashPersonalMessage,
  recoverPublicKey,
  recoverPersonalSignature,
  formatTestTransaction,
  getChainData
} from "./helpers/utilities";
import { IAssetData } from "./helpers/types";
import { fonts } from "./styles";
import {
  ETH_SEND_TRANSACTION,
  ETH_SIGN,
  PERSONAL_SIGN,
  DAI_BALANCE_OF,
  DAI_TRANSFER
} from "./constants";
import { callBalanceOf, callTransfer } from "./helpers/web3";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  getAccountBalance, tokenBalance, stakingApprove, staking, unstaking, getStakesLength, getPackageInfo, tokenSymbol, tokenApproveCheck, stakedAmount, totalStakedAmount, getAllPairs, getStakesRewardInfo,
} from "./ERC721A/contract";
import logo from '../src/assets/logo.svg'
import { Account } from "ethereumjs-util";


const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

interface IResultTableStyleProps {
  nested?: boolean;
}

const STable = styled(SContainer) <IResultTableStyleProps>`
  flex-direction: column;
  min-height: ${({ nested }) => (nested ? "auto" : "200px")};
  text-align: left;
`;

const SRow = styled.div<IResultTableStyleProps>`
  width: 100%;
  display: ${({ nested }) => (nested ? "block" : "flex")};
  margin: 6px 0;
`;

const SKey = styled.div<IResultTableStyleProps>`
  width: ${({ nested }) => (nested ? "100%" : "30%")};
  font-weight: 700;
`;

const SValue = styled.div<IResultTableStyleProps>`
  width: ${({ nested }) => (nested ? "100%" : "70%")};
  font-family: monospace;
`;

const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #fff;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: #fff !important;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: #fff !important;
  }
  .MuiInputLabel-outlined{
    color: #fff !important;
  }
`;

const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0 18px;
`;

// const SContainer = styled.div`
//   height: 100%;
//   min-height: 200px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   word-break: break-word;
// `;

const SLanding = styled(Column)`
  height: 600px;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;

// @ts-ignore
const SBalances = styled(SLanding)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const STestButton = styled(Button)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  max-width: 175px;
  margin: 12px;
`;

interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  showModal: boolean;
  showModal1: boolean;
  staking: boolean;
  unstake: boolean;
  pendingRequest: boolean;
  result: any | null;
  buyOptions: {
    quantity: any,
    eth: any
  };
  salesConfig: any | null;
  userNFTBalance: 0;
  stakeAmount: any;
  indexValue: string;
  stakingBg: string,
  unStakingBg: string,
  userStakeData: [],
  packageInformation: {},
  accountBal: number,
  tokenBal: number,
  symbols: string,
  approvedTokens: string,
  userStakeBal: number,
  totalStaked: number,
  allPairsData: any,
  userStakesDataLength: any,
  selectUserStakeData: [],
  Package: any,
  customloader: boolean,
  show: boolean,
  modalClass: string,
  stakedAmmount: string,
  stakingpackage: string,
  interest1: string,
  interest2: string,
  valueDate: string,
  redemptionDate: string,
  stakepairAddress: string,
  stakingIndex: number,
  stakingtoken0Address: string,
  checked: false,
}

const INITIAL_STATE: any = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  staking: true,
  unstake: false,
  // chainId: 97,
  // networkId: 97,
  chainId: 56,
  networkId: 56,
  assets: [],
  showModal: false,
  showModal1: false,
  accountBal: 0,
  tokenBal: 0,
  symbols: '',
  pendingRequest: false,
  result: null,
  userStakeData: [],
  packageInformation: {
    _daysBlocked: ''
  },
  stakeAmount: '',
  indexValue: '',
  stakingBg: 'active',
  unStakingBg: '',
  buyOptions: {
    quantity: 1,
    eth: 0.044,
  },
  userNFTBalance: 0,
  approvedTokens: '',
  userStakeBal: 0,
  totalStaked: 0,
  allPairsData: [],
  userStakesDataLength: [],
  selectUserStakeData: [],
  Package: "",
  customloader: false,
  show: false,
  modalClass: '',
  stakedAmmount: '',
  stakingpackage: '',
  interest1: '',
  interest2: '',
  valueDate: '',
  redemptionDate: '',
  stakepairAddress: '',
  stakingIndex: null,
  stakingtoken0Address: '',
  checked: false,
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions()
    });

  }

  public async componentDidMount() {
    this.initTotalSupply()
    // if (this.web3Modal.cachedProvider) {
    //   this.onConnect();
    // }

  }


  public initTotalSupply = async () => {
    // testnet https://data-seed-prebsc-1-s1.binance.org:8545/
    // mainnet https://bsc-dataseed.binance.org/
    const web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"))
    let allPairs = await getAllPairs(56, web3, '')
    this.setState({
      allPairsData: allPairs
    });
  }

  public onConnect = async () => {
    this.setState({ customloader: true })
    const provider = await this.web3Modal.connect();
    await this.subscribeProvider(provider);
    await provider.enable();
    const web3: any = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    if (accounts) {
      setTimeout(() => {
        this.setState({ customloader: false })
      }, 5000)

    }
    // const getAccountBalance = await getAccountBalance();
    const address = accounts[0];

    const networkId = await web3.eth.net.getId();

    const chainId = await web3.eth.chainId();
    const balance = await getAccountBalance(chainId, web3, address);
    const tokenBals = await tokenBalance(chainId, web3, address);
    const tokensSymbol = await tokenSymbol(chainId, web3, address);
    let userStakes = 0
    let packageResult = {}
    let userStakedAmount = await stakedAmount(chainId, web3, address)
    let allPairs = await getAllPairs(chainId, web3, address)
    let userStakeRewardInfo = await getStakesRewardInfo(chainId, web3, address)
    let userStakesLength = await getStakesLength(chainId, web3, address);
    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId,
      userStakeData: userStakeRewardInfo,
      userStakesDataLength: userStakesLength,
      packageInformation: packageResult,
      accountBal: balance,
      tokenBal: tokenBals,
      symbols: tokensSymbol,
      userStakeBal: userStakedAmount,
      allPairsData: allPairs,
    });
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      await this.setState({ address: accounts[0] });
      await this.getAccountAssets();
    });
    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await this.setState({ chainId, networkId });
      await this.getAccountAssets();
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = this.state;
      const chainId = await web3.eth.chainId();
      await this.setState({ chainId, networkId });
      await this.getAccountAssets();
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const infuraId = '9ffff3c51c4f458c89cb71497c614726';
    const providerOptions = {
      walletconnect: {
        package: WalletConnect,
        options: {
          // infuraId
          networkId: 56,
          chainId: 56,
          network: 'binance',
          rpc: {
            56: "https://bsc-dataseed.binance.org/",
          }
        }
      },
      // torus: {
      //   package: Torus
      // },
      // walletlink: {
      //   package: WalletLink,
      //   options: {
      //     appName: "Web3Modal Example App",
      //     infuraId
      //   }
      // }
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, assets });
    } catch (error) {
      console.error(error); // tslint:disable-line
      await this.setState({ fetching: false });
    }
  };

  public toggleModal = () =>
    this.setState({ showModal: !this.state.showModal });
  public toggleModal1 = () => {
    this.setState({ showModal1: !this.state.showModal1 });
  }



  public testSendTransaction = async () => {
    const { web3, address, chainId } = this.state;

    if (!web3) {
      return;
    }

    const tx = await formatTestTransaction(address, chainId);

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // @ts-ignore
      function sendTransaction(_tx: any) {
        return new Promise((resolve, reject) => {
          web3.eth
            .sendTransaction(_tx)
            .once("transactionHash", (txHash: string) => resolve(txHash))
            .catch((err: any) => reject(err));
        });
      }

      // send transaction
      const result = await sendTransaction(tx);

      // format displayed result
      const formattedResult = {
        action: ETH_SEND_TRANSACTION,
        txHash: result,
        from: address,
        to: address,
        value: "0 ETH"
      };

      // display result
      this.setState({
        web3,
        pendingRequest: false,
        result: formattedResult || null
      });
    } catch (error) {
      console.error(error); // tslint:disable-line
      this.setState({ web3, pendingRequest: false, result: null });
    }
  };

  public testSignMessage = async () => {
    const { web3, address } = this.state;

    if (!web3) {
      return;
    }

    // test message
    const message = "";

    // hash message
    const hash = hashPersonalMessage(message);

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send message
      const result = await web3.eth.sign(hash, address);

      // verify signature
      const signer = recoverPublicKey(result, hash);
      const verified = signer.toLowerCase() === address.toLowerCase();

      // format displayed result
      const formattedResult = {
        action: ETH_SIGN,
        address,
        signer,
        verified,
        result
      };

      // display result
      this.setState({
        web3,
        pendingRequest: false,
        result: formattedResult || null
      });
    } catch (error) {
      console.error(error); // tslint:disable-line
      this.setState({ web3, pendingRequest: false, result: null });
    }
  };
  public getMintBalance = () => {
    if (this.state.salesConfig?.publicPrice != "100000000000") {
      return (Number(this.state.salesConfig?.mintPrice * 2) / 1e18).toFixed(3);
    }
    return 0;

  }

  public testSignPersonalMessage = async () => {
    const { web3, address } = this.state;

    if (!web3) {
      return;
    }

    // test message
    const message = "My email is john@doe.com - 1537836206101";

    // encode message (hex)
    const hexMsg = convertUtf8ToHex(message);

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send message
      const result = await web3.eth.personal.sign(hexMsg, address);

      // verify signature
      const signer = recoverPersonalSignature(result, message);
      const verified = signer.toLowerCase() === address.toLowerCase();

      // format displayed result
      const formattedResult = {
        action: PERSONAL_SIGN,
        address,
        signer,
        verified,
        result
      };

      // display result
      this.setState({
        web3,
        pendingRequest: false,
        result: formattedResult || null
      });
    } catch (error) {
      console.error(error); // tslint:disable-line
      this.setState({ web3, pendingRequest: false, result: null });
    }
  };

  public testContractCall = async (web3: any) => {

    const { address, chainId } = this.state;
    try {

    } catch (error) {
      console.error(error); // tslint:disable-line
      this.setState({ web3, pendingRequest: false, result: null });
    }
  };

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
    this.initTotalSupply()
  };


  public handleStake = () => {
    this.setState({
      staking: true,
      unstake: false,
      stakingBg: 'active',
      unStakingBg: ''
    })

  }

  public handelUnstake = () => {
    this.setState({
      staking: false,
      unstake: true,
      stakingBg: '',
      unStakingBg: 'active'
    })
  }

  public handleChange = (value: any, index: number, event: any) => {
    const obj = (value.value).split('-')
    this.state.allPairsData[index]["daysLocked"] = Number(obj[2])
    this.state.allPairsData[index]["selectedApy"] = Number(obj[1])
    this.state.allPairsData[index]["selectedPacakge"] = (obj[0])
    this.setState({
      allPairsData: this.state.allPairsData,
      Package: event.target.value
    });
  };


  public onApprove = async (data: any, pairAddress: any, token0Address: any, index: any) => {
    const { web3, address, chainId, stakeAmount, allPairsData } = this.state;
    let approveStaking = await stakingApprove(chainId, web3, address, allPairsData[index].stakeAmount, token0Address, pairAddress);
    if (approveStaking.status == true) {
      this.setState({
        Package: "",
        data: allPairsData[index].stakeAmount = ''
      })
      toast.success('Successfully approved, Please stake now..!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const approvedTokensResult = await tokenApproveCheck(chainId, web3, address, token0Address, pairAddress);
      await this.setState({
        approvedTokens: approvedTokensResult
      })
      this.onConnect();
    }

  }


  // public onStake = async (pairAddress: any, packageName: any, token0Address: any, index: any) => {
  public onStake = async () => {
    // console.log("data", pairAddress, packageName, token0Address);
    const { web3, address, chainId, stakeAmount, allPairsData, stakepairAddress, stakingIndex, stakingpackage, stakingtoken0Address } = this.state;
    let approvedTokensResult = await tokenApproveCheck(chainId, web3, address, stakingtoken0Address, stakepairAddress);
    await this.setState({
      approvedTokens: approvedTokensResult
    })
    if (Number(approvedTokensResult) / 1e9 >= Number(allPairsData[stakingIndex].stakeAmount)) {
      let stakingResult: any = await staking(chainId, web3, address, Number(allPairsData[stakingIndex].stakeAmount), stakepairAddress, stakingpackage, stakingtoken0Address);
      if (stakingResult.status == true) {
        this.setState({
          data: allPairsData[stakingIndex].stakeAmount = '',
          Package: ""
        })
        toast.success('Successfully staked, Please check your staking information..!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.onConnect();
        this.closeModal()
      }
      if (this.web3Modal.cachedProvider) {
        this.onConnect();
      }
    }
    else {
      toast.error(`Approved Amount is ${Number(approvedTokensResult) / 1e9}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  public onUnStake = async (value: any, pairAddress: any) => {
    console.log("pairAddress", value, pairAddress);
    const { web3, address, chainId, indexValue } = this.state;
    let unStakingResult: any = await unstaking(chainId, web3, address, value, pairAddress);
    if (unStakingResult.status == true) {
      this.setState({ indexValue: '' })
      toast.success('Successfully unstaked..!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    }

  }


  public openModal = (data: any, index: any) => {
    console.log(data)
    this.setState({
      show: true,
      modalClass: 'show',
      stakedAmmount: data.stakeAmount + ' ' + data.token1Symbol,
      stakingpackage: data.selectedPacakge,
      interest1: (((data.selectedApy * Number(data.stakeAmount)) / 100) / 12).toFixed(2),
      interest2: ((((data.selectedApy * Number(data.stakeAmount)) / 100) / 12) + Number(data.stakeAmount)).toFixed(2),
      valueDate: moment(new Date()).format("MM-DD-YYYY"),
      redemptionDate: moment(new Date()).add(data.daysLocked, 'd').format("MM-DD-YYYY"),
      stakepairAddress: data.pairAddress,
      stakingIndex: index,
      stakingtoken0Address: data.token0Address
    })
  }
  public closeModal = () => {
    this.setState({
      show: false, modalClass: '',
      stakedAmmount: '',
      stakingpackage: '',
      interest1: '',
      interest2: '',
      valueDate: '',
      redemptionDate: '',
      stakepairAddress: '',
      stakingIndex: null,
      stakingtoken0Address: ''
    })
  }

  handleChangeCheck = (event: any) => {
    this.setState({ checked: event.target.checked })

  };

  public render = () => {

    const {
      assets,
      address,
      connected,
      chainId,
      fetching,
      showModal,
      showModal1,
      pendingRequest,
      result,
      userNFTBalance,
      userStakeData,
      packageInformation,
      accountBal,
      tokenBal,
      symbols,
      approvedTokens,
      userStakeBal,
      userStakesDataLength,
      allPairsData,
      show,
      modalClass,
      stakedAmmount,
      stakingpackage,
      interest1,
      interest2,
      valueDate,
      redemptionDate
    } = this.state;
    return (
      <>
        {chainId == 56 ?
          <>
            {/* <Column maxWidth={1000} spanHeight> */}
            {this.state.customloader &&
              <CustomLoader />
            }
            <header style={{ position: 'relative' }}>

              <nav className="navbar navbar-top-default navbar-expand-lg navbar-simple nav-box-round">
                <div className="container-fluid navbar-bg-1">
                  <a href="#" title="Tippin Tigers" className="logo">
                    <img
                      src={logo}
                      alt="Defi Staking" className="logo-dark" />
                  </a>
                  &nbsp;  &nbsp;  &nbsp;  &nbsp;
                  <div className="col-md-2" style={{ display: "flex", gap: "43px" }}>
                    <a href="https://defiswap.io/pool" target="_blank" style={{ color: 'blue' }}> Pools</a>
                    <a href="https://defiswap.io/swap" target="_blank" style={{ color: 'blue' }}> Swap</a>
                  </div>
                  <div className="col-md-2" >

                  </div>
                  <div className="collapse navbar-collapse" id="megaone">
                    <div className="navbar-nav ml-auto ">
                      <span className="ui-block-03 button">

                      </span>
                      <span className="ui-block-03 button">

                      </span>
                      <span className="ui-block-03 button">

                      </span> <span className="ui-block-03 button">

                      </span>
                      <span className="ui-block-03 button">

                      </span>

                      <span className="ui-block-03 button">

                      </span>
                      <span className="ui-block-03 button">
                      </span>
                    </div>
                  </div>
                </div>
              </nav>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              {/* Same as */}
              <ToastContainer />

            </header>


            <section className="container">

              <div className="row">
                <div className="col-md-12" style={{ textAlign: 'end' }}>
                  <div className="mt-5">

                  </div>
                  {connected ?
                    <button
                      className="btn btn-large btn-rounded cw-btn cw-btn-new"

                      onClick={this.resetApp} >DISCONNECT WALLET</button>
                    :
                    <button
                      className="btn btn-large btn-rounded cw-btn cw-btn-new"
                      onClick={this.onConnect} >CONNECT WALLET</button>
                  }

                </div>
              </div>
              <div className="row">
                {connected &&
                  <div className="col-md-12  text-center">
                    <div className="mt-5">

                    </div>
                    <h2 className="heading-clr mt-2"> </h2>

                    <div className="token-box">
                      <h6 className="pb-3">
                        Account Information
                      </h6>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12" style={{ textAlign: 'left' }}>
                            <CopyToClipboard text={address}
                              onCopy={() => {
                                // setIsCopy(true)
                                toast.success('Wallet Address Copied.!', {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                })
                              }
                              }>
                              <strong className="custom-font-size" style={{ color: '#fff' }}><span style={{ color: "#007cf4" }}>Address&nbsp;&nbsp;</span>
                                {`${address?.substring(0, 10)}...${address?.substring(address?.length - 10, address?.length)}`}
                                {address &&
                                  <>
                                    &nbsp; <i className="fa fa-copy"></i>
                                  </>
                                }
                              </strong>
                            </CopyToClipboard>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ textAlign: 'left' }}>
                            <strong className="custom-font-size" style={{ color: '#fff' }}><span style={{ color: "#007cf4" }}>Token Balance&nbsp;:&nbsp;</span> {(tokenBal / 1e9).toFixed(2)} {symbols}</strong>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12" style={{ textAlign: 'left' }}>
                            <strong className="custom-font-size" style={{ color: '#fff' }}><span style={{ color: "#007cf4" }}>Balance&nbsp;:&nbsp;</span> {(accountBal / 1e18).toFixed(2)} BNB</strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                        </div>
                      </div>
                      <div className="col-md-12" style={{ textAlign: 'left' }}>

                      </div>

                    </div>

                  </div>
                }
                <div className="col-md-3"></div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {allPairsData && allPairsData.length > 0 ?
                    <h4 className="pb-3 texts" style={{ color: "#fff" }}> Staking Pools </h4> : ''}
                  {allPairsData.map((item: any, index: any) => {
                    return <div key={index+1}>
                      <div className="row" >
                        <div className="col-md-12">
                          <div className="card card-body card-new" style={{ padding: '10px !important' }}>
                            <div className="row" >
                              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-6 col-xs-6">
                                    <div color="text" className="sc-be365e-0 QveLg"
                                      style={{
                                        fontSize: "18px",
                                        // lineHeight: 1,
                                        color: '#0076f3', //"rgb(219 148 148)",
                                        fontWeight: "600",
                                      }}
                                    >{item.token0Symbol}-{item.token1Symbol}</div>
                                  </div>
                                  <div className="col-lg-12 col-md-12 col-sm-6 col-xs-6">
                                    <div color="textSubtle" className="sc-be365e-0 hwHTvH" style={{
                                      fontSize: "14px",
                                      lineHeight: 1,
                                      color: "#FFFF",
                                      fontWeight: "400",
                                    }}>Stake {item.token0Symbol} Earn {item.token1Symbol}</div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div color="text" className="sc-be365e-0 QveLg"
                                  style={{
                                    fontSize: "18px",
                                    // lineHeight: 1,
                                    color: '#0076f3',
                                    fontWeight: "600",
                                  }}
                                >Total Staked</div>
                                <div color="textSubtle" className="sc-be365e-0 hwHTvH" style={{
                                  fontSize: "14px",
                                  // lineHeight: 1,
                                  color: "#fff",
                                  fontWeight: "400",
                                }}>{item.totalStakedAmount} {item.token0Symbol}</div>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">

                                {connected ?
                                  <a className="" data-toggle="collapse"
                                    href={`#collapseExample${index + 1}`} role="button"
                                    aria-expanded="false" aria-controls="collapseExample">
                                    <button className="btn btn-large  btn-rounded btn-primary">
                                      <Tooltip style={{ color: 'greenyellow', fontSize: "12px", }}
                                        title="Click here to stake">
                                        <span style={{

                                          // lineHeight: 1,
                                          color: "white",
                                          fontWeight: "600",
                                        }}> Stake</span>
                                      </Tooltip>

                                    </button>
                                  </a>

                                  :
                                  <button
                                    className="btn btn-large btn-rounded cw-btn cw-btn-new"
                                    onClick={this.onConnect} >CONNECT WALLET</button>
                                }
                              </div>
                            </div>
                            <div className="collapse pt-3" id={`collapseExample${index + 1}`}>
                              <div className="card card-body card-box-shadow">
                                <div className="row">
                                  <div className="col-md-3" style={{ color: "#fff" }}>
                                    <a target="_blank" style={{ cursor: 'pointer' }} rel="noreferrer noopener" href="https://bscscan.com/address/0x485f321e448eEF06A8bD6c453fC636538e19d96A#code" className="sc-be365e-0 sc-1efcadde-0 kDIIm gUJZAT sc-569ec8c4-1 lkQwGP" color="primary">View Contract<svg viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" className="sc-5a69fd5e-0 bQHWlV"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg></a>
                                    <div>
                                      <span style={{
                                        // fontSize: "18px",
                                        // lineHeight: 1,
                                        color: '#0076f3',
                                        fontWeight: "600",
                                      }}>Staking Token</span> : {item.token0Symbol}
                                      <br />
                                      <span style={{
                                        // fontSize: "18px",
                                        // lineHeight: 1,
                                        color: '#0076f3',
                                        fontWeight: "600",
                                      }}>{item.token0Symbol} Balance</span>  : {Number(item.token0Balance).toFixed(3)}
                                    </div>
                                  </div>
                                  <div className="col-md-1">
                                    <label style={{ color: 'white' }}>Amount</label>
                                  </div>
                                  <div className="col-md-3">
                                    <CustomTextField
                                      type="text"
                                      // color="secondary"
                                      id="outlined-basic" label="Enter Amount" variant="outlined"
                                      // className="input-white"
                                      InputProps={{
                                        style: {
                                          color: "white",
                                        },
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name="stakeAmount"
                                      // placeholder="Enter Amount"
                                      value={this.state.allPairsData[index].stakeAmount}
                                      onChange={(e) => {
                                        let data = e.target.value;
                                        let stakedata = [...allPairsData]
                                        stakedata[index]['stakeAmount'] = data
                                        this.setState({ allPairsData: stakedata })
                                      }}
                                    />
                                    <div style={{ textAlign: 'left', marginTop: '4%' }}>
                                      &nbsp;&nbsp;&nbsp;
                                      <>
                                        {Number(approvedTokens) / 1e9 && Number(approvedTokens) / 1e9 > 0 && item.selectedPacakge && item.selectedApy && item.stakeAmount ?
                                          <>
                                            <button type="submit" className="btn btn-large  btn-rounded btn-approve"
                                              onClick={() => this.onApprove(item, item.pairAddress, item.token0Address, index)}>Approve</button>&nbsp;

                                            <button type="submit" className="btn btn-large btn-rounded btn-approve"
                                              //  onClick={() => this.onStake(item.pairAddress, item.selectedPacakge ? item.selectedPacakge : '', item.token0Address, index)}
                                              onClick={() => this.openModal(item, index)}
                                            >Stake</button>

                                          </> :
                                          <>
                                            <button type="submit" className="btn btn-large  btn-rounded btn-approve"
                                              onClick={() => this.onApprove(item, item.pairAddress, item.token0Address, index)}>Approve</button>&nbsp;

                                            <button type="submit" className="btn btn-large btn-rounded btn-stake"
                                              // onClick={() => this.onStake(item.pairAddress, item.selectedPacakge ? item.selectedPacakge : '', item.token0Address, index)}
                                              disabled
                                            >Stake</button></>}
                                      </>
                                    </div>
                                  </div>
                                  <div className="col-md-1">
                                    <label style={{ color: 'white' }}>Package</label>
                                  </div>
                                  <div className="col-md-2">
                                    <Box sx={{ minWidth: 120 }}>
                                      <FormControl fullWidth>
                                        <select
                                          style={{ height: '40px', borderRadius: '10px', backgroundColor: '#303446', width: '130px', color: "white" }} value={this.state.Package}
                                          onChange={(event) => {
                                            this.handleChange(event.target, index, event)
                                          }}
                                        >
                                          <option label={"Select"} value={0}>SELECT</option>
                                          {item && item.packageInfo.length && item.packageInfo.map((x: any, i: any) => {
                                            return <option key={i+1} value={`${x["_packageName"]}-${x["_packageInterest"]}-${x["_daysLocked"]}`} label={x["_packageName"]}>{x["_packageName"]}</option>
                                          })}
                                        </select>
                                      </FormControl>
                                    </Box>
                                  </div>
                                  <div className="col-md-2">
                                    {item.selectedApy && item.selectedApy ?
                                      <div style={{ color: 'white', textAlign: 'left' }}> APY - {item.selectedApy + "%"}</div> : null}
                                    <div >
                                      {item.daysLocked && item.daysLocked ?
                                        <div style={{ color: 'white', textAlign: 'left' }}>Days Locked-{item.daysLocked}</div> : null}
                                    </div>
                                  </div>
                                </div>
                                <br /><br />
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              </div>
              <div className="col-md-12">
                {userStakesDataLength && userStakesDataLength.length > 0 && userStakesDataLength.map((item: any, index: any) => {
                  return <div key={index+1}>
                    {item.stakeRewardInfo && item.stakeRewardInfo.length > 0 &&
                      <div>
                        <h4 className="texts" style={{ color: "#fff" }}>{item.token0Symbol}-{item.token1Symbol} Pool Staking Information </h4>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card card-body card-new" style={{ padding: '10px !important' }}>
                              <div className="row" >
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mob-view">
                                  <div color="text" className="sc-be365e-0 QveLg"
                                    style={{
                                      // fontSize: "18px",
                                      // lineHeight: 1,
                                      color: '#0076f3',
                                      fontWeight: "600",
                                    }}
                                  >{item.token0Symbol}-{item.token1Symbol}</div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mob-view">
                                  <div color="text" className="sc-be365e-0 QveLg">
                                    <span style={{
                                      color: '#0076f3',
                                      fontWeight: "600",
                                    }}>Total Staked</span> <span style={{ color: '#fff' }}>: {item.totalStakedAmount} {item.token0Symbol}</span></div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 mob-view">
                                  <span style={{
                                    color: '#0076f3',
                                    fontWeight: "600",
                                  }}> Total Tokens Staked By User</span> <span style={{ color: '#fff' }}>: {item.userStakedAmount} {item.token0Symbol} </span>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 mob-view">
                                  &nbsp;   &nbsp;   &nbsp;
                                  <a className="" data-toggle="collapse"

                                    href={`#usercollapseExample${index + 1}`} role="button"
                                    aria-expanded="false" aria-controls="collapseExample">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" style={{ color: '#0076f3' }} viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                  </a>
                                </div>
                              </div>
                              <br />
                              <div className="collapse" id={`usercollapseExample${index + 1}`}>
                                <table className="table">
                                  <thead>
                                    <th>S.No</th>
                                    <th>Amount</th>
                                    <th>Package</th>
                                    <th>APY</th>
                                    <th>Staked Date</th>
                                    <th>End Date</th>
                                    <th>Yield</th>
                                    <th>Actions</th>
                                  </thead>
                                  <tbody>
                                    {item.stakeRewardInfo && item.stakeRewardInfo.length != 0 && item.stakeRewardInfo.map((z: any, i: any) => {
                                      return <tr key={i+1}>
                                        <td data-label="S.No">
                                          {i + 1}
                                        </td>
                                        <td data-label="Amount">
                                          {z["_amount"]}
                                        </td>
                                        <td data-label="Package">
                                          {z["_packageName"]}
                                        </td>
                                        <td data-label="APY">
                                          {item && item.packageInfo && item.packageInfo.length && item.packageInfo.map((x: any, i: any) => {
                                            return <div key={i+1}>
                                              {x["_packageName"] == z["_packageName"] ?
                                                <div>{x["_packageInterest"]}%</div> : ""}
                                            </div>
                                          })}
                                        </td>
                                        <td data-label="Staked Date">
                                          {z["_timestamp"] && moment.unix(z["_timestamp"]).format("YYYY-MM-DD HH:mm")}
                                        </td>
                                        <td data-label="End Date">
                                          {item && item.packageInfo && item.packageInfo.length && item.packageInfo.map((x: any, i: any) => {
                                            return <div  key={i+1}>
                                              {x["_packageName"] == z["_packageName"] ?
                                                <div
                                                  style={{
                                                    fontSize: "16px",
                                                    // lineHeight: 1,
                                                    color: "#fff",
                                                    fontWeight: "600",
                                                  }}
                                                 
                                                >{z["_timestamp"] && x["_daysBlocked"] && moment(moment.unix(z["_timestamp"]).format("YYYY-MM-DD HH:mm")).add(x["_daysBlocked"], 'd').format("YYYY-MM-DD HH:mm")}	</div> : null}
                                            </div>
                                          })}
                                        </td>
                                        <td data-label="Yield">
                                          {z["yieldReward"].substring(0, 15)}
                                        </td>
                                        <td data-label="Actions">
                                          <button type="submit" className="btn btn-large btn-rounded cw-btn" disabled={z["_withdrawnTimestamp"] != 0
                                          } onClick={() => this.onUnStake(i, item.pairAddress)}>Unstake</button>
                                        </td>
                                      </tr>
                                    })}


                                  </tbody>
                                </table>

                              </div>

                            </div>
                            <br />
                          </div>
                        </div>
                      </div>}
                  </div>

                })}

              </div>
            </section>

            <Modal show={showModal} toggleModal={this.toggleModal}>
              {pendingRequest ? (
                <SModalContainer>
                  <SModalTitle>{"Pending Call Request"}</SModalTitle>
                  <SContainer>
                    <Loader />
                    <SModalParagraph>
                      {"Approve or reject request using your wallet"}
                    </SModalParagraph>
                  </SContainer>
                </SModalContainer>
              ) : result ? (
                <SModalContainer>
                  <SModalTitle>{"Call Request Approved"}</SModalTitle>
                  <ModalResult>{result}</ModalResult>
                </SModalContainer>
              ) : (
                <SModalContainer>
                  <SModalTitle>{"Call Request Rejected"}</SModalTitle>
                </SModalContainer>
              )}
            </Modal>

            {/* popup start */}

            <div className={`modal fade ${modalClass}`} id="exampleModal" style={{ display: show ? "block" : "none" }}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                    <div className="row flex-resp" style={{ alignItems: 'center', width: "100%" }}>
                      <div>
                        <img
                          src={logo}
                          alt="Defi Staking" className="logo-dark" />
                      </div>
                      <div>
                        Staking Details
                      </div>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">

                    <div className="row flex-resp">
                      <div style={{ color: "#007cf4" }}>Staked Amount:</div>
                      <div>{stakedAmmount}</div>
                    </div>
                    <div className="row flex-resp" >
                      <div style={{ color: "#007cf4" }}>Package selected:</div>
                      <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'flex-end' }}><span>{stakingpackage} </span> <span>APY (Annual percentage yield) 30%</span></div>
                    </div>
                    <div className="row flex-resp">
                      <div style={{ color: "#007cf4" }}>Interest:</div>
                      <div>{interest1} DEFC = {interest2} DEFC</div>
                    </div>
                    <div className="row flex-resp">
                      <div style={{ color: "#007cf4" }}>Value date</div>
                      <div>{valueDate}</div>
                    </div>
                    <div className="row flex-resp">
                      <div style={{ color: "#007cf4" }}>Redemption date</div>
                      <div>{redemptionDate}</div>
                    </div>
                    <div className="row flex-resp" style={{ textAlign: "left" }}>
                      <p>Please be aware that the funds will be locked and so won't be possible to unstake until the end of the staking period.</p>
                    </div>
                    <div style={{ marginLeft: "-15px" }}>
                      <input type="checkbox" className="checkbox" style={{ appearance: "auto" }} checked={this.state.checked}
                        onChange={this.handleChangeCheck} /> <span>I have read and I agree with the terms above</span>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-large  btn-rounded btn-approve" data-dismiss="modal" onClick={this.closeModal}>Close</button>
                    {this.state.checked ?
                      <button type="button" className="btn btn-large  btn-rounded btn-approve"
                        onClick={() => this.onStake()}
                      >Confirm Stake</button> : <button type="button" className="btn btn-large  btn-rounded btn-approve"
                        disabled
                      >Confirm Stake</button>}
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <header>
            <div className="container-fluid top-bar">
            </div>
            <nav className="navbar navbar-top-default navbar-expand-lg navbar-simple nav-box-round">
              <div className="container-fluid navbar-bg-1">
                <a href="#" title="Tippin Tigers" className="logo">
                  <img src={logo} alt="Defi Staking" className="logo-dark" />
                </a>
                &nbsp;  &nbsp;  &nbsp;  &nbsp;
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <span style={{ color: 'red', fontSize: '30px' }}><b>Invalid Provider, Please connect to BSC Mainnet</b></span>

                </div>
                <div className="collapse navbar-collapse" id="megaone">
                  <div className="navbar-nav ml-auto ">
                    <span className="ui-block-03 button">

                    </span>
                    <span className="ui-block-03 button">

                    </span>
                    <span className="ui-block-03 button">

                    </span> <span className="ui-block-03 button">

                    </span>
                    <span className="ui-block-03 button">

                    </span>

                    <span className="ui-block-03 button">

                    </span>
                    <span className="ui-block-03 button">
                    </span>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        }
      </>
    );
  };

  public updateTotalSupply = async () => {
    let salesConfig = {} //await getPresaleFeatures(this.state.chainId, this.state.web3);
    this.setState({ ...salesConfig });
  }
}

export default App;
