import { DEFI_CONTRACTS } from './abi';
import moment from 'moment';
import { rejects } from 'assert';
const nativeToken = DEFI_CONTRACTS.mainnet.tokenAddress

const getDefiTokenContract = (chainId: number, web3: any, tokenAddress: any) => {
    if (chainId == 56) {
        const tokenInstance = new web3.eth.Contract(DEFI_CONTRACTS.mainnet.abi, DEFI_CONTRACTS.mainnet.tokenAddress)
        return tokenInstance;
    }

}

const getFactoryContract = (chainId: number, web3: any) => {
    if (chainId == 56) {
        const factoryInstance = new web3.eth.Contract(DEFI_CONTRACTS.mainnet.factoryAbi, DEFI_CONTRACTS.mainnet.factoryAddress)
        return factoryInstance;
    }
}

const getDefiStakingContract = (chainId: number, web3: any, poolAddress: any) => {
    if (chainId == 56) {
        const stakingInstance = new web3.eth.Contract(DEFI_CONTRACTS.mainnet.stakingAbi, poolAddress)
        return stakingInstance;
    }
}

export const getAllPairs = async (chainId: any, web3: any, account: any) => {
    try {
        const factoryContractInstance = getFactoryContract(chainId, web3);
        let allPairsCount = await factoryContractInstance.methods.allPairsLength().call()
        let pools = []
        let token0Balance=0;
        for (let i = 0; i < Number(allPairsCount); i++) {
            let pair = await factoryContractInstance.methods.allPairs(i).call()
            const stakingPoolInstance = new web3.eth.Contract(DEFI_CONTRACTS.mainnet.stakingAbi, pair)
            let totalStakedAmount = await stakingPoolInstance.methods.totalStakedFunds().call()
            let packageCount = await stakingPoolInstance.methods.packageLength().call()
            let packageDetails = []
            for (let j = 0; j < Number(packageCount); j++) {
                let packageIndex = await stakingPoolInstance.methods.packageNames(j).call()
                let packageInfo = await stakingPoolInstance.methods.packages(packageIndex).call()
                packageDetails.push(packageInfo)
            }
            let pairInfo = await factoryContractInstance.methods.getPairInfo(pair).call()
            let token0Instance = await new web3.eth.Contract(DEFI_CONTRACTS.mainnet.abi, pairInfo.token0Address)
            let token1Instance = await new web3.eth.Contract(DEFI_CONTRACTS.mainnet.abi, pairInfo.token1Address)
            let token0Symbol = await token0Instance.methods.symbol().call()
            let token0Decimals = await token0Instance.methods.decimals().call()
            let token1Symbol = await token1Instance.methods.symbol().call()
            if(account){
             token0Balance = await token0Instance.methods.balanceOf(account).call() / Math.pow(10, token0Decimals)
            }else{
                token0Balance = 0
            }
            pairInfo["token0Symbol"] = token0Symbol
            pairInfo["token1Symbol"] = token1Symbol
            pairInfo["packageInfo"] = packageDetails
            pairInfo["totalStakedAmount"] = totalStakedAmount
            pairInfo["token0Balance"] = token0Balance
            pools.push(pairInfo)
        }
        return pools

    } catch (err) {
        console.log(err)
        return err
    }
}

export const tokenApproveCheck = async (chainId: any, web3: any, account: any, tokenAddress: any, stakingPoolAddress: any) => {
    try {
        const tokenContractInstance = getDefiTokenContract(chainId, web3, tokenAddress);
        let spender = stakingPoolAddress
        const result = await tokenContractInstance.methods.allowance(account, spender).call()
        return result; // if result > 0, user approved else not
    } catch (err) {
        console.log(err)
        return err;
    }
}

export const tokenBalance = async (chainId: any, web3: any, account: any) => {
    try {
        const tokenContractInstance = getDefiTokenContract(chainId, web3, DEFI_CONTRACTS.mainnet.tokenAddress);
        const result = await tokenContractInstance.methods.balanceOf(account).call()
        return result;
    } catch (err) {
        console.log(err)
        return err;
    }
}

export const tokenSymbol = async (chainId: any, web3: any, account: any) => {
    try {
        const tokenContractInstance = getDefiTokenContract(chainId, web3, nativeToken);
        const result = await tokenContractInstance.methods.symbol().call()
        return result;
    } catch (err) {
        console.log(err)
        return err;
    }
}

export const stakedAmount = async (chainId: any, web3: any, account: any) => {
    try {
        const allPools: any = await getAllPairs(chainId, web3, account)

        let sum = 0;
        for (let i = 0; i < allPools.length; i++) {
            const stakingContractInstance = getDefiStakingContract(chainId, web3, allPools[i].pairAddress);
            const result = await stakingContractInstance.methods.totalStakedBalance(account).call()
            sum = sum + Number(result);
        }
        return sum;
    } catch (err) {
        console.log(err)
        return err;
    }
}
export const totalStakedAmount = async (chainId: any, web3: any, pairAddress: any) => {
    try {
        const stakingContractInstance = getDefiStakingContract(chainId, web3, pairAddress);
        const result = await stakingContractInstance.methods.totalStakedFunds().call()
        return result;
    } catch (err) {
        console.log(err)
        return err;
    }
}

export const stakingApprove = async (chainId: any, web3: any, account: any, amount: any, tokenAddress: any, stakingPoolAddress: any) => {
    const tokenContractInstance = getDefiTokenContract(chainId, web3, tokenAddress);
    const address = stakingPoolAddress;
    const result = await tokenContractInstance.methods.approve(address, amount * 1e9).send({ from: account });
    return result;
}

export const staking = async (chainId: any, web3: any, account: any, amount: any, pairAddress: any, packageNames: any, token0Address: any) => {
    const stakingContractInstance = getDefiStakingContract(chainId, web3, pairAddress);
    const packageString = packageNames ? packageNames : 'Gold';
    console.log("PACKAGE", packageString)
    if (token0Address.toLocaleLowerCase() == nativeToken.toLocaleLowerCase()) {
        return new Promise((resolve, reject) => {
            stakingContractInstance.methods.stakeTokens(amount, packageString, 0)
                .send({ from: account })
                .then((data: any) => {
                    if (data.status == true) {
                        resolve(data)
                    }
                })
                .catch((err: any) => {
                    reject(err);
                })
        })
    }
    else {
        return new Promise((resolve, reject) => {
            stakingContractInstance.methods.stakeTokens(amount, packageString, 1)
                .send({ from: account })
                .then((data: any) => {
                    if (data.status == true) {
                        resolve(data)
                    }
                })
                .catch((err: any) => {
                    reject(err);
                })
        })
    }

}

export const unstaking = async (chainId: any, web3: any, account: any, index: any, pairAddress: any) => {
    const stakingContractInstance = getDefiStakingContract(chainId, web3, pairAddress);
    return new Promise((resolve, reject) => {
        stakingContractInstance.methods.unstake(Number(index))
            .send({ from: account })
            .then((data: any) => {
                if (data.status == true) {
                    resolve(data)
                }
            })
            .catch((err: any) => {
                reject(err);
            })
    })


}

export const getStakesLength = async (chainId: any, web3: any, account: any) => {
    try {
        const poolsWithRewards: any = await getAllPairs(chainId, web3, account)

        for (let i = 0; i < poolsWithRewards.length; i++) {
            const stakingContractInstance = getDefiStakingContract(chainId, web3, poolsWithRewards[i].pairAddress);
            let stakeCount = await stakingContractInstance.methods.stakesLength(account).call()
            let userStakes = []
            if (poolsWithRewards[i].token0Address.toLocaleLowerCase() == nativeToken.toLocaleLowerCase()) {

                for (let j = 0; j < Number(stakeCount); j++) {
                    let stakeRewards = {}
                    let stakeInfo = await stakingContractInstance.methods.stakes(account, j).call()
                    stakeRewards = await stakingContractInstance.methods.checkNativeStakeReward(account, j).call()
                    let stakeRewardInfo = {
                        ...stakeInfo,
                        ...stakeRewards
                    };
                    userStakes.push(stakeRewardInfo);

                }
            }
            else {
                for (let j = 0; j < Number(stakeCount); j++) {
                    let stakeRewards = {}
                    let stakeInfo = await stakingContractInstance.methods.stakes(account, j).call()
                    stakeRewards = await stakingContractInstance.methods.checkOtherStakeReward(account, j).call()

                    let stakeRewardInfo = {
                        ...stakeInfo,
                        ...stakeRewards
                    };
                    userStakes.push(stakeRewardInfo);

                }
            }
            const stakesResult = await stakingContractInstance.methods.totalStakedBalance(account).call();
            poolsWithRewards[i]["userStakedAmount"] = stakesResult
            poolsWithRewards[i]["stakeRewardInfo"] = userStakes
        }
        return poolsWithRewards

    }
    catch (ex) {
        console.log(ex);
    }

}

export const getStakesRewardInfo = async (chainId: any, web3: any, account: any) => {
    let userStakeRewardInfo = await getStakesLength(chainId, web3, account)
    let stakesAndRewards: any = []
    if (userStakeRewardInfo && userStakeRewardInfo.length > 0) {
        userStakeRewardInfo.forEach((x: any) => {
            if (x.stakeRewardInfo && x.stakeRewardInfo.length > 0) {
                x.stakeRewardInfo.forEach((y: any) => {
                    let obj: any = {
                        ...y,
                        token0: x.token0Symbol,
                        token1: x.token1Symbol,
                        pairAddress: x.pairAddress
                    }
                    stakesAndRewards.push(obj)
                })
            }
        })
    }
    return stakesAndRewards;
}
export const getPackageInfo = async (chainId: any, web3: any, account: any, pairAddress: any, packageString: any) => {
    try {
        const stakingContractInstance = getDefiStakingContract(chainId, web3, pairAddress);

        let PackageInfo = await stakingContractInstance.methods.packages(packageString).call()
        return PackageInfo
    }
    catch (ex) {
        console.log(ex);
    }

}


export const getAccountBalance = async (chainId: any, web3: any, account: any) => {
    return (web3.eth.getBalance(account));
}



// export const getUserNFTBalance = async (chainId: any, web3: any, address: any, tokenAddress:any) => {
//     const contractInstance = getDefiTokenContract(chainId, web3, tokenAddress);

//     const balanceOfUser = await contractInstance.methods.balanceOf(address).call();

//     return balanceOfUser
// }
// export const getPresaleFeatures = async (chainId: any, web3: any, tokenAddress:any) => {
//     const contractInstance = getDefiTokenContract(chainId, web3, tokenAddress);
//     const totalSupply = await contractInstance.methods.totalSupply().call();
//     const salesConfig = await contractInstance.methods.getSalesConfig().call();
//     const block = await web3.eth.getBlock('latest');
//     const publicSaleBeginTime = salesConfig[3] - block.timestamp;
//     return { totalSupply, mintPrice: salesConfig[0], publicPrice: salesConfig[1], key: salesConfig[2], preSaleOnTime: salesConfig[3], publicSaleBeginTime }
// }

// export const getTotalsupply = async (chainId: any, web3: any, tokenAddress:any) => {
//     const contractInstance = getDefiTokenContract(chainId, web3, tokenAddress);
//     const totalSupply = await contractInstance.methods.totalSupply().call();

//     return { totalSupply }
// }

// export const buyInPreSale = async (chainId: any, web3: any, account: any, amount: any, tokenAddress:any) => {
//     const contractInstance = getDefiTokenContract(chainId, web3, tokenAddress);
//     const purchased = await contractInstance.methods.allowlistMint(1).send({ from: account, value: amount });
// }
// export const buyInPublicSale = async (chainId: any, web3: any, account: any, amount: any, quantity: any, tokenAddress:any) => {
//     try {
//         const contractInstance = getDefiTokenContract(chainId, web3, tokenAddress);
//         const salesConfig = await contractInstance.methods.getSalesConfig().call();
//         let utcTime = moment.utc().unix();
//         let price = (quantity * 2) * (0.022 * 1e18);
//         const publicSale = await contractInstance.methods.publicSaleMint(quantity * 2, 4444).send({ from: account, value: price });
//         return publicSale;
//     }
//     catch (ex) {
//         console.log(ex);
//     }

// }

