export const colors: { [name: string]: string } = {
  white: "255, 255, 255",
  black: "0, 0, 0",
  dark: "12, 12, 13",
  grey: "169, 169, 188",
  darkGrey: "113, 119, 138",
  lightGrey: "212, 212, 212",
  blue: "101, 127, 230",
  lightBlue: "64, 153, 255",
  yellow: "250, 188, 45",
  orange: "246, 133, 27",
  green: "84, 209, 146",
  pink: "255, 51, 102",
  red: "214, 75, 71",
  purple: "110, 107, 233",
};

export const fonts = {
  size: {
    tiny: "10px",
    small: "14px",
    medium: "16px",
    large: "18px",
    h1: "60px",
    h2: "50px",
    h3: "40px",
    h4: "32px",
    h5: "24px",
    h6: "20px",
  },
  weight: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  family: {
    OpenSans: '"Open Sans", sans-serif',
  },
};

export const transitions = {
  short: "all 0.1s ease-in-out",
  base: "all 0.2s ease-in-out",
  long: "all 0.3s ease-in-out",
  button: "all 0.15s ease-in-out",
};

export const shadows = {
  soft:
    "0 4px 6px 0 rgba(50, 50, 93, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.08), inset 0 0 1px 0 rgba(0, 0, 0, 0.06)",
  medium:
    "0 3px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(50, 50, 93, 0.02), 0 5px 10px 0 rgba(59, 59, 92, 0.08)",
  big:
    "0 15px 35px 0 rgba(50, 50, 93, 0.06), 0 5px 15px 0 rgba(50, 50, 93, 0.15)",
  hover:
    "0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08), inset 0 0 1px 0 rgba(0, 0, 0, 0.06)",
};

export const responsive = {
  xs: {
    min: "min-width: 467px",
    max: "max-width: 468px",
  },
  sm: {
    min: "min-width: 639px",
    max: "max-width: 640px",
  },
  md: {
    min: "min-width: 959px",
    max: "max-width: 960px",
  },
  lg: {
    min: "min-width: 1023px",
    max: "max-width: 1024px",
  },
  xl: {
    min: "min-width: 1399px",
    max: "max-width: 1400px",
  },
};

export const globalStyle = `
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700,800');

  html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  iframe{
    position: absolute !important;
    z-index:0 !important;
  }
  .btn.disabled, .btn:disabled {
    opacity: .65 !important;
    cursor: not-allowed !important;
}
.card-new{
  background-color: #303446 !important;
    border-radius: 1.25rem !important;
    padding: 9px !important;
    box-shadow: 0 4px 8px 0 #007bf4bf !important;
    /* box-shadow: 0 0 5px 2px #007bf4bf!important; */
    border: 1px solid #1e88e5d9;
    background-image: none;
    /* background-color: transparent; */
    /* background-clip: content-box, border-box; */
    animation: 2s ease-in-out 0s infinite normal none running nnItE;
}
.card-table{
  padding:9px !important;
  background-color: #303446 !important;
  padding: 9px !important;
  box-shadow: 0 4px 8px 0 #007bf4bf !important;
  border: 1px solid #1e88e5d9;
  background-image: none;
  animation: 2s ease-in-out 0s infinite normal none running nnItE;
}

.web3modal-modal-card{
  background-color: #303446 !important;
  padding: 9px !important;
  box-shadow: 0 4px 8px 0 #007bf4bf !important;
  border: 3px solid #1e88e5d9;
  background-image: none;
  animation: 2s ease-in-out 0s infinite normal none running nnItE;
}
.web3modal-provider-container{
  background-color: #303446 !important;
}
.web3modal-provider-name{
  color:#fff !important;
}
.ui-block-03.button .btn.btn-rounded {
  border-radius: 8px !important;
  background-color: #303446 !important;
  /* padding: 9px !important; */
  box-shadow: 0 4px 8px 0 #007bf4bf !important;
  border: 3px solid #1e88e5d9 !important;
  background-image: none !important;
  animation: 2s ease-in-out 0s infinite normal none running nnItE !important;
}


.card-table-th{
  padding:22px !important;
  background-color: #303446 !important;
    // border-radius: 1.25rem !important;
    padding: 9px !important;
    box-shadow: 0 4px 8px 0 #007bf4bf !important;
    /* box-shadow: 0 0 5px 2px #007bf4bf!important; */
    border: 1px solid #1e88e5d9;
    background-image: none;
    /* background-color: transparent; */
    /* background-clip: content-box, border-box; */
    animation: 2s ease-in-out 0s infinite normal none running nnItE;
}
.form-control{
  background-color: transparent !important;
}
.form-control:focus{
  color: #fff !important;
  border-color:#fff !important;
}
.card-wallet{
  background-color: transparent !important;
  box-shadow: 0 0 5px 2px #007bf4bf!important;
}
.cw-btn-new{
  height: 41px;
  opacity: 1;
  background-color: #007cf4 !important;
  background-color: #007cf4 !important;
    box-shadow: 0 4px 8px 0 #007bf4bf;
    border: 1px solid #007cf4;
}
.texts{
  font-family: 'Poppins', sans-serif !important;
    font-size: 22px !important;
    color: #fff !important;
    font-weight: bold !important;
    line-height: 30px !important;
    text-align: center !important;
    display: flex !important;
}
  .active{
    background-color:blue !important;
  }
  .web3modal-modal-lightbox{
    z-index:9999 !important;
  }
  .btn.btn-small {
    font-size: 12px;
    padding: 0px 0.8em !important;
}
.btn.btn-large {
  font-size: 14px;
  padding: 3px 15px !important;
  line-height: 1.8em;
}
.btn.btn-large-free {
  font-size: 13px;
  padding: 3px 13px !important;
  line-height: 1.8em;
  margin-left: -24px;
}
  body {
    font-family: ${fonts.family.OpenSans};
    font-style: normal;
    font-stretch: normal;
    font-weight: ${fonts.weight.normal};
    font-size: ${fonts.size.medium};
    background-color: rgb(${colors.white});
    color: rgb(${colors.dark});
    overflow-y:auto;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  	-webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;  
  }

  button {
    border-style: none;
    line-height: 1em;
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }

  [tabindex] {
    outline: none;
    width: 100%;
    height: 100%;
  }

  a, p, h1, h2, h3, h4, h5, h6 {
  	text-decoration: none;
  	margin: 0;
    padding: 0;
    margin: 0.7em 0;
  }

  h1 {
    font-size: ${fonts.size.h1}
  }
  h2 {
    font-size: ${fonts.size.h2}
  }
  h3 {
    font-size: ${fonts.size.h3}
  }
  h4 {
    font-size: ${fonts.size.h4}
  }
  h5 {
    font-size: ${fonts.size.h5}
  }
  h6 {
    font-size: ${fonts.size.h6}
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;  
    text-decoration: none;
    color: inherit;
    outline: none;
  }

  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  ul, li {
  	list-style: none;
  	margin: 0;
  	padding: 0;
  }

  * {
    box-sizing: border-box !important;
  }

  .card-box-shadow{
     box-shadow: 0 1px 4px 0 #fff;
    // border: 1px solid #fff;
    background-color: #303446 !important;
  }

  .btn-approve{
    background-color: #34af34;
    color: #fff !important;
    box-shadow: 0 4px 8px 0 #34af34;
    border: 1px solid #34af34;
  }
  .btn-stake{
    background-color: #a9a9a9;
    color: #000 !important;
box-shadow: 0 4px 8px 0 #a9a9a9;
border: 1px solid #a9a9a9;

  }
  @media screen and (min-width: 320px) {
    .custom-font-size{
      font-size:13px;
    }
  }


  input {
    -webkit-appearance: none;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
  .modal-content{
    background: #30354a;
    border-radius: 6px !important;
    // padding: 10px 20px !important;
    margin-bottom: 20px !important;
    box-shadow: 0 4px 8px 0 #007bf4bf !important;
    border: 3px solid #1e88e5d9;
    background-image: none;
    /* background-color: transparent; */
    /* background-clip: content-box, border-box; */
    animation: 2s ease-in-out 0s infinite normal none running nnItE;
    color: #fff;
    
  }
  .showmodal{
    z-index:9999;
  }
  .flex-resp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .modal-body{
padding:2rem !important;
  }
  .modal{
    overflow: scroll !important;
  }
`;
